import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const AIInsights = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'Generative AI & Predictive Analytics for Marketers',
          // description: 'Replace or augment your use of Google Analytics with an alternative that is more powerful and privacy compliant.',
          substring:
               'ListenLayer knows every touchpoint, session, user, person, and company interacting with your marketing - so you can analyze and optimize faster and better.',
     }
     const headerIntroCard = {
          description: `<span>What if your analytics tool prompted you to define goals, crawled your website to learn about your business, built profiles about the people and companies interacting with your marketing, and then generated reporting and insights to help drive results...</span>`,
          fName: 'Zachary W. Randall',
          fPosition: 'Founder & CEO',
          imageURL: '/images/hero-image.png',
     }

     const data = [
          {
               title: 'Step 1:',
               content: 'Define Your Goals',
               desc: 'ListenLayer allows you to define goals like conversions, funnels, sales pipelines, target accounts, and cohorts. ',
          },
          {
               title: 'Step 2:',
               content: 'Automate Learning',
               desc:
                    'Your ListenLayer account contains an intelligent agent capable of understanding your goals, learning your business (through crawling your website), and receiving feedback.',
          },
          {
               title: 'Step 3:',
               content: 'Generate Insights',
               desc:
                    'Watch as ListenLayer generates automated insights to streamline your analysis process and help you optimize towards marketing outcomes.',
          },
     ]

     const dataCartText = [
          {
               title: 'Remain Compliant',
               desc:
                    'Remain compliant with privacy laws while still leveraging the power of AI within your marketing. ListenLayer is compatible with GDPR, CCPA/CPRA, other privacy laws, and is one of the only HIPAA compliant analytics and tagging tools. You can even replace your current CMP!',
          },
          {
               title: `It's <span>Your Data</span> ... Own It!`,
               desc: 'ListenLayer does not sell or use your data for our own benefit (unlike those "free" analytics tools).',
               otherDesc: "Your data belongs to you - which is why we're able to allow you to do things you never thought possible!",
          },
          {
               title: 'Security-first Architecture',
               desc: 'We take the security of your data seriously, with full encryption, access controls, and proactive measures.',
               otherDesc: 'ListenLayer is currently pursuing SOC2, Type 2 compliance to reflect our commitment to security.',
          },
     ]

     const pageName = 'AI Marketing Insights'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]
     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} />

               <section>
                    <div class="container">
                         <div class="headline-bodytext-wrapper bodytext-width-large  ml-0">
                              <div class="bodyCopy">
                                   <p>We have entered the era of AI, and the benefit to marketers is unparalleled.</p>
                                   <p>
                                        Imagine having your own unlimited, intelligent computer that understands your business, allows you to define
                                        your goals, and then helps you analyze your website traffic, competitive behavior, and marketing results.
                                   </p>
                                   <p>ListenLayer allows this, all while respecting your data and visitor's privacy. Here's how it works...</p>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card">
                    <div className="container">
                         <div className="box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-12 col-lg-4 mb-4">
                                                  <div className="card-step h-100">
                                                       <div className="card-body ">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-text">{item.content}</p>
                                                            <p className="card-desc">{item.desc}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
               </section>
               <SectionBoxReady
                    title={'Ready to Dive In?'}
                    content={'See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.'}
                    style={'30px'}
               />

               <SectionBoxCardText data={dataCartText}></SectionBoxCardText>
               {/* <section className="enhance-imgtext-block enhance-img-text">
                    <div className="container">
                         <ImageTextBlock dataImageTextBlock={dataCartText} />
                    </div>
               </section> */}
               <SectionBoxReady
                    title={'Ready to Dive In?'}
                    content={'See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.'}
                    style={'30px'}
                    className={'custom-content'}
               />
          </Layout>
     )
}

export default AIInsights
